export class SettingsPolicyList {
  static readonly type = '[SettingsPolicyList] Post';
  constructor(public param: ISettingsServiceTypeListParam) {}
}

export class SaveSettingsPolicy {
  static readonly type = '[SaveSettingsPolicy] Post';
  constructor(public param: ISaveSettingsPolicyParam) {}
}

export class DeleteSettingsPolicy {
  static readonly type = '[DeleteSettingsPolicy] Post';
  constructor(public param: { id: number }) {}
}

export class UpdateOrderSettingsPolicy {
  static readonly type = '[UpdateOrderSettingsPolicy] Post';
  constructor(public param: IUpdateOrderSettingsPolicyParam) {}
}

export class SettingsServiceTypeList {
  static readonly type = '[SettingsServiceTypeList] Post';
  constructor(public param: ISettingsServiceTypeListParam) {}
}

export class SettingsAddEditServiceType {
  static readonly type = '[SettingsAddEditServiceType] Post';
  constructor(public param: ISettingsAddEditServiceTyepParam) {}
}

export class DeleteSettingsService {
  static readonly type = '[DeleteSettingsService] Post';
  constructor(public param: { id: number }) {}
}

export class SettingsProjectTypeList {
  static readonly type = '[SettingsProjectTypeList] Post';
  constructor(public param: ISettingsServiceTypeListParam) {}
}

export class SaveSettingsProject {
  static readonly type = '[SaveSettingsProject] Post';
  constructor(public param: ISaveSettingsProjectParams) {}
}

export class DeleteSettingsProject {
  static readonly type = '[DeleteSettingsProject] Post';
  constructor(public param: { id: number }) {}
}

export class SettingsProjectCopy {
  static readonly type = '[SettingsProjectCopy] Post';
  constructor(public param: IProjectTypeCopy) {}
}

export class SettingsMilestoneList {
  static readonly type = '[SettingsMilestoneList] Get';
  constructor(public param: { project_type_id: number }) {}
}

export class DeleteSettingsMilestone {
  static readonly type = '[DeleteSettingsMilestone] Post';
  constructor(public param: { id: number }) {}
}
export class SettingsMilestonesAddEdit {
  static readonly type = '[SettingsMilestonesAddEdit] Post';
  constructor(public param: FormData) {}
}

export class UpdateOrderSettingsMilestone {
  static readonly type = '[UpdateOrderSettingsMilestone] Post';
  constructor(public param: IUpdateOrderMilestonesParam) {}
}

export class OnboardingDepartmentList {
  static readonly type = '[OnboardingDepartmentList] Post';
  constructor(public param: { list_for: number }) {}
}

export class OnboardingList {
  static readonly type = '[OnboardingList] Post';
  constructor(public param: IOnboardingListParam) {}
}

export class EvaluationList {
  static readonly type = '[EvaluationList] Post';
}

export class EvaluationAddEdit {
  static readonly type = '[EvaluationAddEdit] Post';
  constructor(public param: IEvaluationAddEdit, public isFirstTime: boolean) {}
}

export class EvaluationPerformanceAddEdit {
  static readonly type = '[EvaluationPerformanceAddEdit] Post';
  constructor(public param: IEvaluationPerformanceAddEdit) {}
}

export class EvaluationPerformanceList {
  static readonly type = '[EvaluationPerformanceList] Post';
  constructor(public param: IEvaluationPerformanceListParam) {}
}

export class EvaluationPublish {
  static readonly type = '[EvaluationPublish] post';
  constructor(public param: IEvaluationPublishParam) {}
}

export class EvaluationDelete {
  static readonly type = '[EvaluationDelete] Post';
  constructor(public param: { id: number }) {}
}

export class EvaluationPerformanceDelete {
  static readonly type = '[EvaluationPerformanceDelete] Post';
  constructor(public param: { id: number; type: 'Add' | 'Edit' }) {}
}
export class SettingsOnboardingSetupAddEdit {
  static readonly type = '[SettingsOnboardingSetupAddEdit] Post';
  constructor(public param: IOnboardingAddEditParam) {}
}

export class DeleteSettingsOnboarding {
  static readonly type = '[DeleteSettingsOnboarding] Post';
  constructor(public param: { id: number }) {}
}
export class SetDepartmentList {
  static readonly type = '[SetDepartmentList] Post';
  constructor(public param?: IOnboardingList) {}
}

export class UpdateOrderSettingsOnboarding {
  static readonly type = '[UpdateOrderSettingsOnboarding] Post';
  constructor(public param: IUpdateOrderSettingsOnboardingParam) {}
}

export class SetSelectedDepartment {
  static readonly type = '[SetSelectedDepartment] Post';
  constructor(public param: IOnboardingDepartmentList) {}
}

export class EvaluationCopy {
  static readonly type = '[EvaluationCopy] Post';
  constructor(public param: { evaluation_id: number }) {}
}

export class DeleteSettingsOnboardingFile {
  static readonly type = '[DeleteSettingsOnboardingFile] Post';
  constructor(public param: IDeleteSettingsOnboardingFile) {}
}

// Enrollment Form Actions
export class EnrollmentFormList {
  static readonly type = '[EnrollmentFormList] Post';
}
export class AddEditEnrollmentForm {
  static readonly type = '[AddEditEnrollmentForm] Post';
  constructor(
    public param: ISettingAddEditEnrollmentFormParam,
    public isFirstTime: boolean
  ) {}
}
export class DeleteEnrollmentForm {
  static readonly type = '[DeleteEnrollmentForm] Post';
  constructor(public param: IEnrollmentFormDeleteParam) {}
}
export class EnrollmentFormCopy {
  static readonly type = '[EnrollmentFormCopy] Post';
  constructor(
    public param: { enrollment_form_id: number; form_version: string }
  ) {}
}
export class EnrollmentFormPublish {
  static readonly type = '[EnrollmentFormPublish] post';
  constructor(public param: { id: number }) {}
}
export class AddEditEnrollmentFormElement {
  static readonly type = '[AddEditEnrollmentFormElement] Post';
  constructor(public param: IAddEditEnrollmentFormElementParam) {}
}

export class FetchEnrollmentFormElementList {
  static readonly type = '[FetchEnrollmentFormElementList] Post';
  constructor(public param: IEnrollmentFormElementListParam) {}
}

export class DeleteEnrollmentFormElement {
  static readonly type = '[DeleteEnrollmentFormElement] Post';
  constructor(public param: IEnrollmentFormElementDeleteParam) {}
}

export class UpdateOrderSettingsEnrollmentFormElement {
  static readonly type = '[UpdateOrderSettingsEnrollmentFormElement] Post';
  constructor(public param: IUpdateOrderEnrollmentFormParam) {}
}

export class EnrollmentFormFetch {
  static readonly type = '[EnrollmentFormFetch] Post';
  constructor(public param: { user_id: number }) {}
}

export class EnrollmentFormAddEdit {
  static readonly type = '[EnrollmentFormAddEdit] Post';
  constructor(public param: FormData) {}
}

export class TechnologyList {
  static readonly type = '[TechnologyList] Post';
  constructor(public param: {}) {}
}

export class SetSelectedTechnology {
  static readonly type = '[SetSelectedTechnology] Post';
  constructor(public param: ITechnologyList) {}
}

export class PluginList {
  static readonly type = '[PluginList] Post';
  constructor(public param: IPluginParam) {}
}

export class PluginAddEdit {
  static readonly type = '[PluginAddEdit] Post';
  constructor(public param: IPluginAddEditParam) {}
}

export class DeletePlugin {
  static readonly type = '[DeletePlugin] Post';
  constructor(public param: IDeletePluginParam) {}
}

export class CMSList {
  static readonly type = '[CMSList] Post';
  constructor(public param: ICMSListParam) {}
}

export class MaseterPageList {
  static readonly type = '[MaseterPageList] Post';
  constructor(public param: {}) {}
}

export class CMSAddEdit {
  static readonly type = '[CMSAddEdit] Post';
  constructor(public param: IAddEditCMS) {}
}

export class DeleteCMS {
  static readonly type = '[DeleteCMS] Post';
  constructor(public param: IDeleteCMS) {}
}

export class OnBoardingUploadFile {
  static readonly type = '[OnBoardingUploadFile] Post';
  constructor(public param: FormData) {}
}

export class ResetOnboardingSlider {
  static readonly type = '[ResetOnboardingSlider] Post';
}
