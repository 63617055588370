import { Injectable } from '@angular/core';
import { HttpService } from '@app/core/http';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import {
  DeleteSettingsMilestone,
  DeleteSettingsOnboarding,
  DeleteSettingsPolicy,
  DeleteSettingsProject,
  DeleteSettingsService,
  EvaluationAddEdit,
  EvaluationCopy,
  EvaluationDelete,
  EvaluationList,
  EvaluationPerformanceAddEdit,
  EvaluationPerformanceDelete,
  EvaluationPerformanceList,
  EvaluationPublish,
  OnboardingDepartmentList,
  OnboardingList,
  SaveSettingsPolicy,
  SaveSettingsProject,
  SetDepartmentList,
  SetSelectedDepartment,
  SettingsAddEditServiceType,
  SettingsMilestoneList,
  SettingsMilestonesAddEdit,
  SettingsOnboardingSetupAddEdit,
  SettingsPolicyList,
  SettingsProjectTypeList,
  SettingsServiceTypeList,
  UpdateOrderSettingsOnboarding,
  UpdateOrderSettingsPolicy,
  UpdateOrderSettingsMilestone,
  DeleteSettingsOnboardingFile,
  FetchEnrollmentFormElementList,
  DeleteEnrollmentFormElement,
  AddEditEnrollmentFormElement,
  EnrollmentFormList,
  AddEditEnrollmentForm,
  DeleteEnrollmentForm,
  EnrollmentFormCopy,
  EnrollmentFormPublish,
  UpdateOrderSettingsEnrollmentFormElement,
  EnrollmentFormAddEdit,
  EnrollmentFormFetch,
  TechnologyList,
  PluginList,
  PluginAddEdit,
  DeletePlugin,
  SetSelectedTechnology,
  CMSList,
  CMSAddEdit,
  DeleteCMS,
  MaseterPageList,
  OnBoardingUploadFile,
  ResetOnboardingSlider,
  SettingsProjectCopy,
} from '../actions/settings.action';
import { tap } from 'rxjs';
import {
  insertItem,
  patch,
  removeItem,
  updateItem,
} from '@ngxs/store/operators';
import { ToastrService } from 'ngx-toastr';

interface ISettingsStateModel {
  allSettingsPolicyList: ISettingsPolicyList[];
  allSettingsPolicyListCount: number;
  settingsPolicyId: number;
  settingsPolicyListParam: ISettingsServiceTypeListParam | null;
  settingsServiceTypeList: ISettingsServiceTypeList[];
  settingsServiceTypeListCount: number;
  settingsProjectTypeList: ISettingsProjectTypeList[];
  settingsProjectTypeListCount: number;
  settingsProjectId: number;
  SettingsProjectCopy: number;

  settingsMileStoneList: ISettingsMilestoneList[];
  settingsMilestoneListCount: number;

  onboardingDepartmentList: IOnboardingDepartmentList[];
  onboardingDepartmentListCount: number;
  onboardingList: IOnboardingList[];
  onboardingListCount: number;
  evaluationList: IEvalutionList[];
  evaluationListCount: number;
  newAddEvaluationResponce: IEvalutionList | null;
  evaluationPerformanceList: IEvaluationPerformanceList[];
  onboardingDepartmentId: number;
  editedOnBoardingData: IOnboardingList | null;
  selectedDepartmentData: IOnboardingDepartmentList | null;
  allEnrollmentFormElement: IFormStructure | null;
  enrollmentFormList: ISettingEnrollmentFormList[];
  enrollmentFormListCount: number;
  newAddEnrollmentResponse: ISettingEnrollmentFormList | null;
  enrollmentFormElementList: IEnrollmentFormElementList[];
  enrollmentFormElementListCount: number;
  enrollmentFormName: string;
  evaluationFormName: string;
  technologyList: ITechnologyList[];
  selectedTechnology: ITechnologyList | null;
  pluginList: IPluginList[];
  pluginListCount: number;
  CMSList: ICMSTypeList[];
  CMSListCount: number;
  masterPageList: IMasterPage[];
  onBoardingId: number;
}
@State<ISettingsStateModel>({
  name: 'SettingsState',
  defaults: {
    allSettingsPolicyList: [],
    allSettingsPolicyListCount: 0,
    settingsPolicyId: 0,
    settingsPolicyListParam: null,
    settingsServiceTypeList: [],
    settingsServiceTypeListCount: 0,
    settingsProjectTypeList: [],
    settingsProjectTypeListCount: 0,
    settingsProjectId: 0,
    SettingsProjectCopy: 0,
    settingsMileStoneList: [],
    settingsMilestoneListCount: 0,
    onboardingDepartmentList: [],
    onboardingDepartmentListCount: 0,
    onboardingList: [],
    onboardingListCount: 0,
    evaluationList: [],
    evaluationListCount: 0,
    newAddEvaluationResponce: null,
    evaluationPerformanceList: [],
    onboardingDepartmentId: 0,
    editedOnBoardingData: null,
    selectedDepartmentData: null,
    enrollmentFormList: [],
    enrollmentFormListCount: 0,
    newAddEnrollmentResponse: null,
    enrollmentFormElementList: [],
    enrollmentFormElementListCount: 0,
    allEnrollmentFormElement: null,
    enrollmentFormName: '',
    evaluationFormName: '',
    technologyList: [],
    selectedTechnology: null,
    pluginList: [],
    pluginListCount: 0,
    CMSList: [],
    CMSListCount: 0,
    masterPageList: [],
    onBoardingId: 0,
  },
})
@Injectable()
export class SettingsState {
  constructor(private _http: HttpService, private _toastr: ToastrService) {}

  @Selector()
  static allSettingsPolicyList(state: ISettingsStateModel) {
    return state.allSettingsPolicyList;
  }

  @Selector()
  static allSettingsPolicyListCount(state: ISettingsStateModel) {
    return state.allSettingsPolicyListCount;
  }
  @Selector()
  static settingsPolicyListParam(state: ISettingsStateModel) {
    return state.settingsPolicyListParam;
  }
  @Selector()
  static settingsServiceTypeList(state: ISettingsStateModel) {
    return state.settingsServiceTypeList;
  }

  @Selector()
  static settingsServiceTypeListCount(state: ISettingsStateModel) {
    return state.settingsServiceTypeListCount;
  }

  @Selector()
  static settingsProjectTypeList(state: ISettingsStateModel) {
    return state.settingsProjectTypeList;
  }

  @Selector()
  static settingsProjectTypeListCount(state: ISettingsStateModel) {
    return state.settingsProjectTypeListCount;
  }

  @Selector()
  static settingsMileStoneList(state: ISettingsStateModel) {
    return state.settingsMileStoneList;
  }

  @Selector()
  static settingsMilestoneListCount(state: ISettingsStateModel) {
    return state.settingsMilestoneListCount;
  }
  @Selector()
  static onboardingDepartmentList(state: ISettingsStateModel) {
    return state.onboardingDepartmentList;
  }

  @Selector()
  static onboardingDepartmentListCount(state: ISettingsStateModel) {
    return state.onboardingDepartmentListCount;
  }

  @Selector()
  static onboardingList(state: ISettingsStateModel) {
    return state.onboardingList;
  }

  @Selector()
  static onboardingListCount(state: ISettingsStateModel) {
    return state.onboardingListCount;
  }

  @Selector()
  static evaluationList(state: ISettingsStateModel) {
    return state.evaluationList;
  }
  @Selector()
  static evaluationListCount(state: ISettingsStateModel) {
    return state.evaluationListCount;
  }

  @Selector()
  static newAddEvaluationResponce(state: ISettingsStateModel) {
    return state.newAddEvaluationResponce;
  }

  @Selector()
  static evaluationPerformanceList(state: ISettingsStateModel) {
    return state.evaluationPerformanceList;
  }
  @Selector()
  static onboardingDepartmentId(state: ISettingsStateModel) {
    return state.onboardingDepartmentId;
  }

  @Selector()
  static editedOnBoardingData(state: ISettingsStateModel) {
    return state.editedOnBoardingData;
  }

  @Selector()
  static selectedDepartmentData(state: ISettingsStateModel) {
    return state.selectedDepartmentData;
  }

  @Selector()
  static enrollmentFormList(state: ISettingsStateModel) {
    return state.enrollmentFormList;
  }
  @Selector()
  static enrollmentFormListCount(state: ISettingsStateModel) {
    return state.enrollmentFormListCount;
  }
  @Selector()
  static newAddEnrollmentResponse(state: ISettingsStateModel) {
    return state.newAddEnrollmentResponse;
  }

  @Selector()
  static enrollmentFormElementList(state: ISettingsStateModel) {
    return state.enrollmentFormElementList;
  }

  @Selector()
  static enrollmentFormElementListCount(state: ISettingsStateModel) {
    return state.enrollmentFormElementListCount;
  }

  @Selector()
  static allEnrollmentFormElement(state: ISettingsStateModel) {
    return state.allEnrollmentFormElement;
  }

  @Selector()
  static enrollmentFormName(state: ISettingsStateModel) {
    return state.enrollmentFormName;
  }
  @Selector()
  static evaluationFormName(state: ISettingsStateModel) {
    return state.evaluationFormName;
  }

  @Selector()
  static technologyList(state: ISettingsStateModel) {
    return state.technologyList;
  }

  @Selector()
  static selectedTechnology(state: ISettingsStateModel) {
    return state.selectedTechnology;
  }

  @Selector()
  static pluginList(state: ISettingsStateModel) {
    return state.pluginList;
  }

  @Selector()
  static pluginListCount(state: ISettingsStateModel) {
    return state.pluginListCount;
  }

  @Selector()
  static cmsList(state: ISettingsStateModel) {
    return state.CMSList;
  }

  @Selector()
  static cmsListCount(state: ISettingsStateModel) {
    return state.CMSListCount;
  }

  @Selector()
  static masterPageList(state: ISettingsStateModel) {
    return state.masterPageList;
  }

  @Selector()
  static onBoardingId(state: ISettingsStateModel) {
    return state.onBoardingId;
  }

  @Action(SettingsPolicyList)
  SettingsPolicyList(
    ctx: StateContext<ISettingsStateModel>,
    { param }: SettingsPolicyList
  ) {
    return this._http.post('policy/list', param).pipe(
      tap((apiResult) => {
        const resultData = apiResult.response.dataset;
        ctx.patchState({
          allSettingsPolicyList: resultData.policy_list,
          allSettingsPolicyListCount: resultData.total_rows,
        });
      })
    );
  }

  @Action(SaveSettingsPolicy)
  SaveSettingsPolicy(
    ctx: StateContext<ISettingsStateModel>,
    { param }: SaveSettingsPolicy
  ) {
    return this._http.post('policy/add-edit', param).pipe(
      tap((apiResult) => {
        const resultData = apiResult.response.dataset;
        ctx.patchState({
          settingsPolicyId: resultData.id,
        });
        this._toastr.success(apiResult.response.status.msg, 'success', {});
      })
    );
  }

  @Action(DeleteSettingsPolicy)
  DeleteSettingsPolicy(
    ctx: StateContext<ISettingsStateModel>,
    { param }: DeleteSettingsPolicy
  ) {
    return this._http.post('policy/delete', param).pipe(
      tap((apiResult) => {
        ctx.setState(
          patch({
            allSettingsPolicyList: removeItem<ISettingsPolicyList>(
              (item) => item.id === param.id
            ),
          })
        );
        this._toastr.success(apiResult.response.status.msg, 'success', {});
      })
    );
  }

  @Action(UpdateOrderSettingsPolicy)
  UpdateOrderSettingsPolicy(
    ctx: StateContext<ISettingsStateModel>,
    { param }: UpdateOrderSettingsPolicy
  ) {
    return this._http.post('policy/update-order', param).pipe(
      tap((apiResult) => {
        this._toastr.success(apiResult.response.status.msg, 'success', {
          closeButton: true,
          timeOut: 3000,
        });
      })
    );
  }

  @Action(SettingsServiceTypeList)
  SettingsServiceTypeList(
    ctx: StateContext<ISettingsStateModel>,
    { param }: SettingsServiceTypeList
  ) {
    return this._http.post('service-type/list', param).pipe(
      tap((apiResult) => {
        const resultData = apiResult.response.dataset;
        ctx.patchState({
          settingsServiceTypeList: resultData.service_types,
          settingsServiceTypeListCount: resultData.total_rows,
        });
      })
    );
  }

  @Action(SettingsAddEditServiceType)
  SettingsAddEditServiceType(
    ctx: StateContext<ISettingsStateModel>,
    { param }: SettingsAddEditServiceType
  ) {
    return this._http.post('service-type/add-edit', param).pipe(
      tap((apiResult) => {
        const serviceTypeID = apiResult.response.dataset.id;
        const state = ctx.getState();
        const serviceTypeListCount = state.settingsServiceTypeListCount;
        if (param.id === 0) {
          ctx.setState(
            patch<ISettingsStateModel>({
              settingsServiceTypeListCount: serviceTypeListCount + 1,
              // settingsServiceTypeList: insertItem<ISettingsServiceTypeList>({
              //   id: serviceTypeID,
              //   service_type: param.service_type,
              //   status: param.status,
              // }),
            })
          );
        } else {
          ctx.setState(
            patch<ISettingsStateModel>({
              settingsServiceTypeList: updateItem<ISettingsServiceTypeList>(
                (item) => item.id === param.id,
                patch({
                  id: serviceTypeID,
                  service_type: param.service_type,
                  status: param.status,
                })
              ),
            })
          );
        }
        this._toastr.success(apiResult.response.status.msg, 'success', {});
      })
    );
  }

  @Action(DeleteSettingsService)
  DeleteSettingsService(
    ctx: StateContext<ISettingsStateModel>,
    { param }: DeleteSettingsService
  ) {
    return this._http.post('service-type/delete', param).pipe(
      tap((apiResult) => {
        this._toastr.success(apiResult.response.status.msg, 'success', {
          closeButton: true,
          timeOut: 3000,
        });
        ctx.setState(
          patch({
            settingsServiceTypeList: removeItem<ISettingsServiceTypeList>(
              (item) => item.id === param.id
            ),
          })
        );
      })
    );
  }

  @Action(SettingsProjectTypeList)
  SettingsProjectTypeList(
    ctx: StateContext<ISettingsStateModel>,
    { param }: SettingsProjectTypeList
  ) {
    return this._http.post('project-type/list', param).pipe(
      tap((apiResult) => {
        const resultData = apiResult.response.dataset;

        ctx.patchState({
          settingsProjectTypeList: resultData.project_types,
          settingsProjectTypeListCount: resultData.total_rows,
        });
      })
    );
  }

  @Action(SaveSettingsProject)
  SaveSettingsProject(
    ctx: StateContext<ISettingsStateModel>,
    { param }: SaveSettingsProject
  ) {
    return this._http.post('project-type/add-edit', param).pipe(
      tap((apiResult) => {
        const resultData = apiResult.response.dataset;

        ctx.patchState({
          settingsProjectId: resultData.id,
        });
        this._toastr.success(apiResult.response.status.msg, 'success', {});
      })
    );
  }

  @Action(DeleteSettingsProject)
  DeleteSettingsProject(
    ctx: StateContext<ISettingsStateModel>,
    { param }: DeleteSettingsProject
  ) {
    return this._http.post('project-type/delete', param).pipe(
      tap((apiResult) => {
        ctx.setState(
          patch({
            settingsProjectTypeList: removeItem<ISettingsProjectTypeList>(
              (item) => item.id === param.id
            ),
          })
        );
        this._toastr.success(apiResult.response.status.msg, 'success', {
          closeButton: true,
          timeOut: 3000,
        });
      })
    );
  }

  @Action(SettingsProjectCopy)
  SettingsProjectCopy(
    ctx: StateContext<ISettingsStateModel>,
    { param }: SettingsProjectCopy
  ) {
    return this._http.post('project-type/copy', param).pipe(
      tap((apiResult) => {
        const resultData = apiResult.response.dataset;

        ctx.patchState({
          SettingsProjectCopy: resultData,
        });
        this._toastr.success(apiResult.response.status.msg, 'success', {
          closeButton: true,
          timeOut: 3000,
        });
      })
    );
  }

  @Action(SettingsMilestoneList)
  SettingsMilestoneList(
    ctx: StateContext<ISettingsStateModel>,
    { param }: SettingsMilestoneList
  ) {
    return this._http
      .post('milestone/list', { project_type_id: param.project_type_id })
      .pipe(
        tap((apiResult) => {
          const resultData = apiResult.response.dataset;
          ctx.patchState({
            settingsMileStoneList: resultData.milestones,
            settingsMilestoneListCount: resultData.total_rows,
          });
        })
      );
  }

  @Action(SettingsMilestonesAddEdit)
  SettingsMilestonesAddEdit(
    ctx: StateContext<ISettingsStateModel>,
    { param }: SettingsMilestonesAddEdit
  ) {
    return this._http.post('milestone/add-edit', param).pipe(
      tap((apiResult) => {
        this._toastr.success(apiResult.response.status.msg, 'success', {
          closeButton: true,
          timeOut: 3000,
        });
      })
    );
  }

  @Action(DeleteSettingsMilestone)
  DeleteSettingsMilestone(
    ctx: StateContext<ISettingsStateModel>,
    { param }: DeleteSettingsMilestone
  ) {
    return this._http.post('milestone/delete', param).pipe(
      tap((apiResult) => {
        ctx.setState(
          patch({
            settingsMileStoneList: removeItem<ISettingsMilestoneList>(
              (item) => item.id === param.id
            ),
          })
        );
        this._toastr.success(apiResult.response.status.msg, 'success', {
          closeButton: true,
          timeOut: 3000,
        });
      })
    );
  }

  @Action(UpdateOrderSettingsMilestone)
  UpdateOrderSettingsMilestone(
    ctx: StateContext<ISettingsStateModel>,
    { param }: UpdateOrderSettingsMilestone
  ) {
    return this._http.post('milestone/update-order', param).pipe(
      tap((apiResult) => {
        this._toastr.success(apiResult.response.status.msg, 'success', {
          closeButton: true,
          timeOut: 3000,
        });
      })
    );
  }

  @Action(OnboardingDepartmentList)
  OnboardingDepartmentList(
    ctx: StateContext<ISettingsStateModel>,
    { param }: OnboardingDepartmentList
  ) {
    return this._http.post('department/list', param).pipe(
      tap((apiResult) => {
        const resultData = apiResult.response.dataset;
        ctx.patchState({
          onboardingDepartmentList: resultData.departments,
          onboardingDepartmentListCount: resultData.total_rows,
        });
      })
    );
  }

  @Action(OnboardingList)
  OnboardingList(
    ctx: StateContext<ISettingsStateModel>,
    { param }: OnboardingList
  ) {
    return this._http.post('onboarding/list', param).pipe(
      tap((apiResult) => {
        const resultData = apiResult.response.dataset;
        ctx.patchState({
          onboardingList: resultData.onboarding,
          onboardingListCount: resultData.total_rows,
          // onboardingDepartmentId: param.dept_id,
        });
      })
    );
  }

  @Action(EvaluationList)
  EvaluationList(ctx: StateContext<ISettingsStateModel>) {
    return this._http.post('evaluation-form/list', {}).pipe(
      tap((apiResult) => {
        const resultData = apiResult.response.dataset;
        ctx.patchState({
          evaluationList: resultData.evaluation,
          evaluationListCount: resultData.evaluation.length,
          evaluationFormName: resultData.evaluation_form_name,
        });
      })
    );
  }

  @Action(EvaluationAddEdit)
  EvaluationAddEdit(
    ctx: StateContext<ISettingsStateModel>,
    { param, isFirstTime }: EvaluationAddEdit
  ) {
    return this._http.post('evaluation-form/add-edit', param).pipe(
      tap((apiResult) => {
        ctx.patchState({
          newAddEvaluationResponce: apiResult.response.dataset,
        });
        if (isFirstTime) {
          this._toastr.success(apiResult.response.status.msg, 'success', {
            closeButton: true,
            timeOut: 3000,
          });
        }
      })
    );
  }

  @Action(EvaluationPerformanceAddEdit)
  EvaluationPerformanceAddEdit(
    ctx: StateContext<ISettingsStateModel>,
    { param }: EvaluationPerformanceAddEdit
  ) {
    return this._http.post('evaluation-form/performance-add-edit', param).pipe(
      tap((apiResult) => {
        this._toastr.success(apiResult.response.status.msg, 'success', {
          closeButton: true,
          timeOut: 3000,
        });
      })
    );
  }

  @Action(SettingsOnboardingSetupAddEdit)
  SettingsOnboardingSetupAddEdit(
    ctx: StateContext<ISettingsStateModel>,
    { param }: SettingsOnboardingSetupAddEdit
  ) {
    return this._http.post('onboarding/add-edit', param).pipe(
      tap((apiResult) => {
        ctx.patchState({
          onBoardingId: apiResult.response.dataset.id,
        });
        this._toastr.success(apiResult.response.status.msg, 'success', {
          closeButton: true,
          timeOut: 3000,
        });
      })
    );
  }

  @Action(EvaluationPerformanceList)
  EvaluationPerformanceList(
    ctx: StateContext<ISettingsStateModel>,
    { param }: EvaluationPerformanceList
  ) {
    return this._http.post('evaluation-form/performance-list', param).pipe(
      tap((apiResult) => {
        ctx.patchState({
          evaluationPerformanceList:
            apiResult.response.dataset.performance_type,
        });
      })
    );
  }

  @Action(EvaluationPublish)
  EvaluationPublish(
    ctx: StateContext<ISettingsStateModel>,
    { param }: EvaluationPublish
  ) {
    return this._http.post('evaluation-form/publish', param).pipe(
      tap((apiResult) => {
        this._toastr.success(apiResult.response.status.msg, 'success', {
          closeButton: true,
          timeOut: 3000,
        });
      })
    );
  }

  @Action(EvaluationDelete)
  EvaluationDelete(
    ctx: StateContext<ISettingsStateModel>,
    { param }: EvaluationDelete
  ) {
    return this._http.post('evaluation-form/delete', param).pipe(
      tap((apiResult) => {
        ctx.setState(
          patch({
            evaluationList: removeItem<IEvalutionList>(
              (item) => item.id === param.id
            ),
          })
        );
        this._toastr.success(apiResult.response.status.msg, 'success', {
          closeButton: true,
          timeOut: 3000,
        });
      })
    );
  }

  @Action(DeleteSettingsOnboarding)
  DeleteSettingsOnboarding(
    ctx: StateContext<ISettingsStateModel>,
    { param }: DeleteSettingsOnboarding
  ) {
    return this._http.post('onboarding/delete', param).pipe(
      tap((apiResult) => {
        ctx.setState(
          patch({
            onboardingList: removeItem<IOnboardingList>(
              (item) => item.id === param.id
            ),
          })
        );
        this._toastr.success(apiResult.response.status.msg, 'success', {
          closeButton: true,
          timeOut: 3000,
        });
      })
    );
  }

  @Action(EvaluationPerformanceDelete)
  EvaluationPerformanceDelete(
    ctx: StateContext<ISettingsStateModel>,
    { param }: EvaluationPerformanceDelete
  ) {
    return this._http.post('evaluation-form/performance-delete', param).pipe(
      tap((apiResult) => {
        ctx.setState(
          patch({
            evaluationPerformanceList: removeItem<IEvaluationPerformanceList>(
              (item) => item.id === param.id
            ),
          })
        );
        this._toastr.success(apiResult.response.status.msg, 'success', {
          closeButton: true,
          timeOut: 3000,
        });
      })
    );
  }

  @Action(SetDepartmentList)
  SetDepartmentList(
    ctx: StateContext<ISettingsStateModel>,
    { param }: SetDepartmentList
  ) {
    return ctx.patchState({
      editedOnBoardingData: param,
    });
  }

  @Action(SetSelectedDepartment)
  SetSelectedDepartment(
    ctx: StateContext<ISettingsStateModel>,
    { param }: SetSelectedDepartment
  ) {
    return ctx.patchState({
      selectedDepartmentData: param,
    });
  }

  @Action(UpdateOrderSettingsOnboarding)
  UpdateOrderSettingsOnboarding(
    ctx: StateContext<ISettingsStateModel>,
    { param }: UpdateOrderSettingsOnboarding
  ) {
    return this._http.post('onboarding/update-order', param).pipe(
      tap((apiResult) => {
        this._toastr.success(apiResult.response.status.msg, 'success', {
          closeButton: true,
          timeOut: 3000,
        });
      })
    );
  }

  @Action(EvaluationCopy)
  EvaluationCopy(
    ctx: StateContext<ISettingsStateModel>,
    { param }: EvaluationCopy
  ) {
    return this._http.post('evaluation-form/copy', param).pipe(
      tap((apiResult) => {
        ctx.patchState({
          newAddEvaluationResponce: apiResult.response.dataset,
        });
        ctx.setState(
          patch<ISettingsStateModel>({
            evaluationList: insertItem<IEvalutionList>(
              apiResult.response.dataset,
              0
            ),
          })
        );
        this._toastr.success(apiResult.response.status.msg, 'success', {
          closeButton: true,
          timeOut: 3000,
        });
      })
    );
  }

  @Action(DeleteSettingsOnboardingFile)
  DeleteSettingsOnboardingFile(
    ctx: StateContext<ISettingsStateModel>,
    { param }: DeleteSettingsOnboardingFile
  ) {
    return this._http.post('file/delete', param).pipe(
      tap((apiResult) => {
        this._toastr.success(apiResult.response.status.msg, 'success', {
          closeButton: true,
          timeOut: 3000,
        });

        ctx.setState(
          patch<ISettingsStateModel>({
            onboardingList: updateItem<IOnboardingList>(
              (item) => item.id === param.id,
              patch({ video: '', file_name: '', onboarding_image: '' })
            ),
          })
        );
      })
    );
  }

  //Enrollment Form
  @Action(EnrollmentFormList)
  EnrollmentFormList(ctx: StateContext<ISettingsStateModel>) {
    return this._http.post('enrollment-form/list', {}).pipe(
      tap((apiResult) => {
        const resultData = apiResult.response.dataset;
        ctx.patchState({
          enrollmentFormList: resultData.enrollments,
          enrollmentFormListCount: resultData.total_rows,
          enrollmentFormName: resultData.enrollment_form_name,
        });
      })
    );
  }

  @Action(EnrollmentFormCopy)
  EnrollmentFormCopy(
    ctx: StateContext<ISettingsStateModel>,
    { param }: EnrollmentFormCopy
  ) {
    return this._http.post('enrollment-form/copy', param).pipe(
      tap((apiResult) => {
        ctx.patchState({
          newAddEnrollmentResponse: apiResult.response.dataset,
        });
        ctx.setState(
          patch<ISettingsStateModel>({
            enrollmentFormList: insertItem<ISettingEnrollmentFormList>(
              apiResult.response.dataset,
              0
            ),
          })
        );
        this._toastr.success(apiResult.response.status.msg, 'success', {
          closeButton: true,
          timeOut: 3000,
        });
      })
    );
  }

  @Action(AddEditEnrollmentForm)
  AddEditEnrollmentForm(
    ctx: StateContext<ISettingsStateModel>,
    { param, isFirstTime }: AddEditEnrollmentForm
  ) {
    return this._http.post('enrollment-form/add-edit', param).pipe(
      tap((apiResult) => {
        ctx.patchState({
          newAddEnrollmentResponse: apiResult.response.dataset,
        });
        if (isFirstTime) {
          this._toastr.success(apiResult.response.status.msg, 'success', {
            closeButton: true,
            timeOut: 3000,
          });
        }
      })
    );
  }

  @Action(DeleteEnrollmentForm)
  DeleteEnrollmentForm(
    ctx: StateContext<ISettingsStateModel>,
    { param }: DeleteEnrollmentForm
  ) {
    return this._http.post('enrollment-form/delete', param).pipe(
      tap((apiResult) => {
        ctx.setState(
          patch({
            enrollmentFormList: removeItem<ISettingEnrollmentFormList>(
              (item) => item.id === param.id
            ),
          })
        );
        this._toastr.success(apiResult.response.status.msg, 'success', {
          closeButton: true,
          timeOut: 3000,
        });
      })
    );
  }

  @Action(EnrollmentFormPublish)
  EnrollmentFormPublish(
    ctx: StateContext<ISettingsStateModel>,
    { param }: EnrollmentFormPublish
  ) {
    return this._http.post('enrollment-form/publish', param).pipe(
      tap((apiResult) => {
        this._toastr.success(apiResult.response.status.msg, 'success', {
          closeButton: true,
          timeOut: 3000,
        });
      })
    );
  }

  @Action(AddEditEnrollmentFormElement)
  AddEditEnrollmentFormElement(
    ctx: StateContext<ISettingsStateModel>,
    { param }: AddEditEnrollmentFormElement
  ) {
    return this._http.post('enrollment-form/element-add-edit', param).pipe(
      tap((apiResult) => {
        this._toastr.success(apiResult.response.status.msg, 'success', {
          closeButton: true,
          timeOut: 3000,
        });
      })
    );
  }

  @Action(FetchEnrollmentFormElementList)
  FetchEnrollmentFormElementList(
    ctx: StateContext<ISettingsStateModel>,
    { param }: FetchEnrollmentFormElementList
  ) {
    return this._http.post('enrollment-form/element-list', param).pipe(
      tap((apiResult) => {
        const resultData = apiResult.response.dataset;
        ctx.patchState({
          enrollmentFormElementList: resultData.form_elements,
          enrollmentFormElementListCount: resultData.total_rows,
        });

        if (param.is_preview) {
          ctx.patchState({
            allEnrollmentFormElement: apiResult.response.dataset,
          });
        }
      })
    );
  }

  @Action(DeleteEnrollmentFormElement)
  DeleteEnrollmentFormElement(
    ctx: StateContext<ISettingsStateModel>,
    { param }: DeleteEnrollmentFormElement
  ) {
    return this._http.post('enrollment-form/element-delete', param).pipe(
      tap((apiResult) => {
        ctx.setState(
          patch({
            enrollmentFormElementList: removeItem<IEnrollmentFormElementList>(
              (item) => item.element_id === param.id
            ),
          })
        );
        this._toastr.success(apiResult.response.status.msg, 'success', {
          closeButton: true,
          timeOut: 3000,
        });
      })
    );
  }

  @Action(UpdateOrderSettingsEnrollmentFormElement)
  UpdateOrderSettingsEnrollmentFormElement(
    ctx: StateContext<ISettingsStateModel>,
    { param }: UpdateOrderSettingsEnrollmentFormElement
  ) {
    return this._http.post('enrollment-form/update-element-order', param).pipe(
      tap((apiResult) => {
        this._toastr.success(apiResult.response.status.msg, 'success', {
          closeButton: true,
          timeOut: 3000,
        });
      })
    );
  }

  @Action(EnrollmentFormFetch)
  EnrollmentFormFetch(
    ctx: StateContext<ISettingsStateModel>,
    { param }: EnrollmentFormFetch
  ) {
    return this._http.post('enrollment/fetch-form', param).pipe(
      tap((apiResult) => {
        ctx.patchState({
          allEnrollmentFormElement: apiResult.response.dataset,
        });
      })
    );
  }

  @Action(EnrollmentFormAddEdit)
  EnrollmentFormAddEdit(
    ctx: StateContext<ISettingsStateModel>,
    { param }: EnrollmentFormAddEdit
  ) {
    return this._http.post('enrollment/add-edit', param).pipe(
      tap((apiResult) => {
        this._toastr.success(apiResult.response.status.msg, 'success', {
          closeButton: true,
          timeOut: 3000,
        });
      })
    );
  }

  @Action(TechnologyList)
  TechnologyList(
    ctx: StateContext<ISettingsStateModel>,
    { param }: TechnologyList
  ) {
    return this._http.post('website/technology-list', param).pipe(
      tap((apiResult) => {
        const resultData = apiResult.response.dataset;
        ctx.patchState({
          technologyList: resultData.technologies,
        });
      })
    );
  }

  @Action(SetSelectedTechnology)
  SetSelectedTechnology(
    ctx: StateContext<ISettingsStateModel>,
    { param }: SetSelectedTechnology
  ) {
    return ctx.patchState({
      selectedTechnology: param,
    });
  }

  @Action(PluginList)
  PluginList(ctx: StateContext<ISettingsStateModel>, { param }: PluginList) {
    return this._http.post('website/plugin-list', param).pipe(
      tap((apiResult) => {
        const resultData = apiResult.response.dataset;
        ctx.patchState({
          pluginList: resultData.plugins,
          pluginListCount: resultData.total_rows,
        });
      })
    );
  }

  @Action(PluginAddEdit)
  PluginAddEdit(
    ctx: StateContext<ISettingsStateModel>,
    { param }: PluginAddEdit
  ) {
    return this._http.post('website/plugin-add-edit', param).pipe(
      tap((apiResult) => {
        this._toastr.success(apiResult.response.status.msg, 'success', {
          closeButton: true,
          timeOut: 3000,
        });
      })
    );
  }

  @Action(DeletePlugin)
  DeletePlugin(
    ctx: StateContext<ISettingsStateModel>,
    { param }: DeletePlugin
  ) {
    return this._http.post('website/plugin-delete', param).pipe(
      tap((apiResult) => {
        this._toastr.success(apiResult.response.status.msg, 'success', {
          closeButton: true,
          timeOut: 3000,
        });

        ctx.setState(
          patch<ISettingsStateModel>({
            pluginList: removeItem<IPluginList>(
              (item) => item.plugin_id === param.plugin_id
            ),
          })
        );
      })
    );
  }

  @Action(CMSList)
  CMSList(ctx: StateContext<ISettingsStateModel>, { param }: CMSList) {
    return this._http.post('content-management/list', param).pipe(
      tap((apiResult) => {
        const resultData = apiResult.response.dataset;
        ctx.patchState({
          CMSList: resultData.cms_data,
          CMSListCount: resultData.total_rows,
        });
      })
    );
  }

  @Action(MaseterPageList)
  MaseterPageList(
    ctx: StateContext<ISettingsStateModel>,
    { param }: MaseterPageList
  ) {
    return this._http.post('content-management/pages', param).pipe(
      tap((apiResult) => {
        const resultData = apiResult.response.dataset;
        ctx.patchState({
          masterPageList: resultData.pages,
        });
      })
    );
  }

  @Action(CMSAddEdit)
  CMSAddEdit(ctx: StateContext<ISettingsStateModel>, { param }: CMSAddEdit) {
    return this._http.post('content-management/add-edit', param).pipe(
      tap((apiResult) => {
        this._toastr.success(apiResult.response.status.msg, 'success', {
          closeButton: true,
          timeOut: 3000,
        });
      })
    );
  }

  @Action(DeleteCMS)
  DeleteCMS(ctx: StateContext<ISettingsStateModel>, { param }: DeleteCMS) {
    return this._http.post('content-management/delete', param).pipe(
      tap((apiResult) => {
        this._toastr.success(apiResult.response.status.msg, 'success', {
          closeButton: true,
          timeOut: 3000,
        });

        ctx.setState(
          patch<ISettingsStateModel>({
            CMSList: removeItem<ICMSTypeList>(
              (item) => item.cms_id === param.cms_id
            ),
          })
        );
      })
    );
  }

  @Action(OnBoardingUploadFile)
  OnBoardingUploadFile(
    ctx: StateContext<ISettingsStateModel>,
    { param }: OnBoardingUploadFile
  ) {
    return this._http.post('file/upload-file', param).pipe(
      tap((apiResult: any) => {
        const resultData = apiResult.link;
      })
    );
  }

  @Action(ResetOnboardingSlider)
  ResetOnboardingSlider(ctx: StateContext<ISettingsStateModel>) {
    return ctx.patchState({ onboardingList: [] });
  }
}
